/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 18, 2021 */
 
@font-face {
	font-family: 'league_spartanbold';
	src: local('league_spartanbold'), url('../fonts/leaguespartan-bold-webfont.woff2') format('woff2'), url('../fonts/leaguespartan-bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
/* Webfont: Lato-Regular */
@font-face {
	font-family: 'LatoWeb';
	src: local('LatoWeb'), url('../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
	src: local('LatoWeb'), url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */ url('../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/Lato-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}


body {
	font-family: 'LatoWeb';
	font-weight: normal;
	font-style: normal;
	color: #63688e;
	overflow-x: hidden;
}
* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.video-react {
	padding-top: 0 !important;
}
img {
	max-width: 100%;
}
a:focus, input:focus, textarea:focus, button:focus {
	text-decoration: none;
	outline: none;
}
a:focus, a:hover {
	text-decoration: none;
}
i, span, a {
	display: inline-block;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'LatoWeb';
	font-weight: 600;
	color: #0a0c19;
	margin: 0px;
}
h1 {
	color: #293045;
	font-family: 'league_spartanbold';
	font-size: 65px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 86px;
}
ul, ol {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}
p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #63688e;
	margin: 0px;
}
.col-lg-6 {
	padding-right: 0;
	padding-left: 0;
}
.bg_cover {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}
.container {
    max-width: 1920px;
    width: 100%;
    padding: 0;
}
.navbar {
	padding: 20px 0 0;
}
.navbar.navbar-expand.navbar-fixed.sub {
	z-index: 99;
}
.header-nav {
	z-index: 999;
	position: relative;
}
 @media(max-width:767px) {
.header-nav {
	padding: 0 0;
	top: 0
}
 
}
.header-nav .navigation .navbar .navbar-toggler {
	border: 0
}
.header-nav .navigation .navbar .navbar-toggler .toggler-icon {
	width: 30px;
	height: 2px;
	background-color: #293045;
	margin: 5px 0;
	display: block;
	position: relative;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.header-nav .navigation .navbar .navbar-toggler.active .toggler-icon:nth-of-type(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 7px
}
.header-nav .navigation .navbar .navbar-toggler.active .toggler-icon:nth-of-type(2) {
	opacity: 0
}
.header-nav .navigation .navbar .navbar-toggler.active .toggler-icon:nth-of-type(3) {
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
	top: -7px
}



@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-collapse {
	position: absolute;
	top: 128%;
	left: 0;
	width: 100%;
	background-color: #ffab4a;
	z-index: 8;
	padding: 10px 16px;
	box-shadow: 0 26px 48px 0 rgba(0,0,0,.15);
}
.partners-area.partners_inpage.desk_view{
	display:none;
	}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-collapse {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: #fff;
	z-index: 8;
	padding: 10px 16px;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
}
 
.header-nav .navigation .navbar .navbar-nav .nav-item a {
	color: #293045!important;
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
}
.nav-link img {
	float: right;
	-webkit-filter: invert(100%);
	filter: invert(100%);
	margin-top: 15px;
}
.header-area_inn_pages .nav-link img {
	float: right;
	-webkit-filter: invert(100%);
	filter: none;
	margin-top: 15px;
}
.partners-area.partners_inpage.desk_view{
	display:none;
	}
.partners-area.partners_inpage .partners_inn .section-title {
     margin-top: 0;
}
.partners-area.partners_inpage .partners-content {
    height: 100%;
    padding-bottom: 0;
}
}
.header-nav .navigation .navbar .navbar-nav {
	list-style-type: none;
	margin: 0;
	padding: 0
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav {
	margin-right: 0
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav {
	margin-right: 0;
	padding: 10px 0 30px;
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item {
	position: relative;
	margin-left: 40px
}
.header-nav .navigation .navbar .navbar-nav .nav-item a {
	color: #fff;
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
}
.header-area.header-area_inn_pages .header-nav .navigation .navbar .navbar-nav .nav-item a {
	color: #293045;
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a>i {
	display: none
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a>i {
	display: none
}
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a {
	padding: 0;
	display: block;
	border: 0;
	margin: 0;
	line-height: 40px
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a {
	padding: 0;
	display: block;
	border: 0;
	margin: 0;
	line-height: 40px
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item a span {
	padding-left: 5px;
	font-size: 15px
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a span {
	display: none
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item a span {
	display: none
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item:first-child a {
	margin-left: 0
}
.header-nav .navigation .navbar .navbar-nav .nav-item:last-child a {
	margin-right: 0
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
	position: absolute;
	left: 0;
	top: 110%;
	width: 150px;
	background-color: #fff;
	opacity: 0;
	visibility: hidden;
	transition: all linear .3s;
	z-index: 99;
	-webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,.16);
	-moz-box-shadow: 0 2px 6px 0 rgba(0,0,0,.16);
	box-shadow: 0 2px 6px 0 rgba(0,0,0,.16);
	list-style-type: none;
	margin: 0;
	padding: 15px 0;
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
	position: relative!important;
	width: 100%!important;
	left: 0!important;
	top: auto!important;
	opacity: 1!important;
	visibility: visible!important;
	display: none;
	right: auto;
	-webkit-transform: translateX(0%);
	-moz-transform: translateX(0%);
	-ms-transform: translateX(0%);
	-o-transform: translateX(0%);
	transform: translateX(0%);
	-webkit-transition: all none ease-out 0s;
	-moz-transition: all none ease-out 0s;
	-ms-transition: all none ease-out 0s;
	-o-transition: all none ease-out 0s;
	transition: all none ease-out 0s;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	text-align: left;
	border-top: 0;
	transition: 0s
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu {
	position: relative!important;
	width: 100%!important;
	left: 0!important;
	top: auto!important;
	opacity: 1!important;
	visibility: visible!important;
	display: none;
	right: auto;
	-webkit-transform: translateX(0%);
	-moz-transform: translateX(0%);
	-ms-transform: translateX(0%);
	-o-transform: translateX(0%);
	transform: translateX(0%);
	-webkit-transition: all none ease-out 0s;
	-moz-transition: all none ease-out 0s;
	-ms-transition: all none ease-out 0s;
	-o-transition: all none ease-out 0s;
	transition: all none ease-out 0s;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	text-align: left;
	border-top: 0;
	transition: 0s
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li {
	position: relative
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	margin-left: 10px
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	margin-left: 0
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	margin-left: 0
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-nav-toggler {
	color: #404040;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a {
	display: block;
	padding: 0 20px;
	position: relative;
	color: #33415c;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
	margin: 0;
	line-height: 2.2;
	font-size: 16px
}
@media only screen and (min-width:1200px) and (max-width:1600px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a {
	padding: 0 20px
}
}
@media only screen and (min-width:992px) and (max-width:1200px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a {
	padding: 0 20px
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a i {
	float: right;
	font-size: 16px;
	margin-top: 5px
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a i {
	display: none
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a i {
	display: none
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li a .sub-nav-toggler i {
	display: inline-block
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	right: auto;
	left: 100%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all .3s ease-out 0s;
	-moz-transition: all .3s ease-out 0s;
	-ms-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	padding-left: 30px;
	-webkit-transition: all 0s ease-out 0s;
	-moz-transition: all 0s ease-out 0s;
	-ms-transition: all 0s ease-out 0s;
	-o-transition: all 0s ease-out 0s;
	transition: all 0s ease-out 0s
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li .sub-menu {
	padding-left: 30px;
	-webkit-transition: all 0s ease-out 0s;
	-moz-transition: all 0s ease-out 0s;
	-ms-transition: all 0s ease-out 0s;
	-o-transition: all 0s ease-out 0s;
	transition: all 0s ease-out 0s
}
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li:hover .sub-menu {
	top: -60%;
	opacity: 1;
	visibility: visible
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li:hover .sub-nav-toggler {
	color: #ffab4a
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-menu>li:hover>a {
	background: #efefef
}
.header-nav .navigation .navbar .navbar-nav .nav-item:hover .sub-menu {
	opacity: 1;
	visibility: visible;
	top: 100%
}
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
	display: none
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px 14px;
	font-size: 16px;
	background: 0 0;
	border: 0;
	color: #fff
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-nav .nav-item .sub-nav-toggler {
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	font-size: 16px;
	background: 0 0;
	border: 0;
	color: #fff;
	width: 100%;
}
.header-nav .navigation .navbar .navbar-nav .nav-item {
	position: relative;
	margin-left: 0;
}
}
@media only screen and (min-width:768px) and (max-width:991px) {
.header-nav .navigation .navbar .navbar-btn {
	position: absolute;
	right: 70px;
	top: 50%;
	transform: translateY(-50%)
}
}
@media(max-width:767px) {
.header-nav .navigation .navbar .navbar-btn {
	position: absolute;
	right: 70px;
	top: 50%;
	transform: translateY(-50%)
}
}
.header-nav .navigation .navbar .navbar-btn a {
	margin-left: 50px
}
.header-area {
	position: relative;
}
.header-area::before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 80%;
	background: #fff
}
.navbar-brand {
	z-index: 9;
}
.header-area.header-area_inn_pages {
	min-height: auto;
	background: #EFEFEF;
	max-width: 100%;
}
.header-area_inn_pages .em-nav .em-nav-list > li > a {
	color: #293045;
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	text-align: right;
}
.header-area_inn_pages .em-nav .em-nav-list > li > a:hover {
	color: #293045;
}
.header-area {
	background-color: #fff;
	min-height: 882px;
	padding: 0 4%;
	max-width: 1920px;
	margin: 0 auto;
}
.header-area::before {
	display: none;
}
.header-content-area .header-content {
	position: relative;
	z-index: 5;
	padding-top: 160px;
	max-width: 600px;
}
.header-content-area .header-content p {
	visibility: visible;
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 25px;
	letter-spacing: 0;
	line-height: 30px;
	padding-top: 15px
}
.header-content-area .header-content .sub-title {
	color: #63688e;
}
.header-content-area .header-content .header-title {
	color: #293045;
	font-size: 70px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 78px;
	font-family: 'league_spartanbold';
	max-width: 600px;
}
.header-content-area .header-content .header-title span {
	color: #1fe075;
}
.header-content-area .header-banner-bg {
	width: 53%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 9;
	overflow: hidden;
	background-color: #EFEFEF;
}
.header-content-area #myVideo {
	width: 100%;
	min-height: 100%;
	object-fit: cover;
}
.partners-area {
	position: relative;
	padding-top: 146px;
	background-color: #EFEFEF;
	margin-top: -60px;
	display: flex;
	padding-left: 0;
}
.partners-area.partners_inpage {
	position: relative;
	padding-top: 146px;
	background-color: #EFEFEF;
	margin-top: -100px;
	display: flex;
	padding-left: 0;
}
.partners_inn {
	width: 100%;
}
.partners-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.partners-content p {
	padding-top: 20px;
}
.partners-image img {
	object-fit: cover;
	z-index: 9;
	position: relative;
}
.partners-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0;
	width: 100%;
}
.btn-section h4 {
	color: #50D0DB;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
}
.btn-section span {
	padding-left: 15px
}
.btn-section {
	flex: 1 1 auto;
	position: relative;
	transition: 0.3s;
	display: inline-block;
}
.btn-section:after {
	position: absolute;
	transition: 0.3s;
	content: "";
	width: 0;
	left: 50%;
	bottom: -5px;
	height: 2px;
	background: #50D0DB;
}
.btn-section:hover {
	cursor: pointer;
}
.btn-section:hover:after {
	width: 100%;
	left: 0;
}
.partners_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
}
.partners_inn .section-title::after {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-ordinal-group: 2;
	box-ordinal-group: 2;
	-moz-order: 2;
	order: 2;
	-ms-flex-order: 2;
	margin-left: 3.5em;
	border-top: 1px solid #aaa;
}
.partners_inn .content_section {
	left: 11%;
	position: relative;
}
.members-area {
	position: relative;
	padding-top: 70px;
	background-color: #fff;
	margin-top: -60px;
	display: flex;
}
.members_inn {
	width: 100%;
}
.members-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.members-content p {
	padding-top: 20px;
}
.members-image img {
	width: 100%;
	object-fit: cover;
	z-index: 9;
	position: relative;
	max-width: 640px;
	float: right;
}
.members-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0;
	width: 100%;
}
.members_inn {
	display: block;
	width: 100%;
}
.members_inn .content_section {
	left: -30px;
	position: relative;
	float: right;
}
.members_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
	width: 60%;
}
.members_inn .section-title::before {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-left: 0;
	border-top: 1px solid #aaa;
	margin-right: 50px;
}
.emclass-area {
	position: relative;
	padding-top: 140px;
	background-color: #EFEFEF;
	margin-top: -230px;
	display: flex;
	padding-left: 0;
}
.emclass_inn {
	width: 100%;
}
.emclass-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.emclass-content p {
	padding-top: 20px;
}
.emclass-image img {
	object-fit: cover;
	z-index: 9;
	position: relative;
}
.emclass-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0%;
	width: 100%;
}
.emclass_inn .content_section {
	left: 11%;
	position: relative;
}
.btn-section h4 {
	color: #50D0DB;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
}
.btn-section h4 a {
	color: #50D0DB;
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
}
.btn-section span {
	padding-left: 15px
}
.emclass_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
}
.emclass_inn .section-title::after {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-ordinal-group: 2;
	box-ordinal-group: 2;
	-moz-order: 2;
	order: 2;
	-ms-flex-order: 2;
	margin-left: 3.5em;
	border-top: 1px solid #aaa;
}
.emborrow-area {
	position: relative;
	padding-top: 70px;
	background-color: #fff;
	margin-top: -60px;
	display: flex;
}
.emborrow_inn {
	width: 100%;
}
.emborrow-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.emborrow-content p {
	padding-top: 20px;
}
.emborrow-image img {
	width: 100%;
	object-fit: cover;
	max-width: 640px;
	float: right;
}
.emborrow-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0;
	width: 100%;
}
.emborrow_inn {
	display: block;
	width: 100%;
}
.emborrow_inn .content_section {
	left: -30px;
	position: relative;
	float: right;
}
.emborrow_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
	width: 60%;
	text-indent: 35px;
}
.emborrow_inn .section-title::before {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-left: 0;
	border-top: 1px solid #aaa;
	margin-right: 50px;
}
.personal-area {
	position: relative;
	padding-top: 110px;
	background-color: #fff;
	margin-top: -80px;
	display: flex;
	padding-bottom: 130px
}
.personal_inn {
    width: 100%;
    padding-bottom: 40px;
}
.personal-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.personal-content p {
	padding-top: 20px;
}
.personal-image img {
	width: 100%;
	object-fit: cover;
	z-index: 9;
	position: relative;
	max-width: 640px;
	float: right
}
.personal-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 515px;
	margin: 40px 0 40px 0;
	width: 100%;
	float: right;
}
.personal {
	display: block;
	width: 100%;
}
.personal .content_section {
	left: 33%;
	position: relative;
}
.personal_inn .section-title {
	position: relative;
	display: flex;
	align-items: baseline;
	width: 100%;
}
.personal_inn .section-title::before {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-left: 0;
	border-top: 1px solid #aaa;
	margin-right: 50px;
}
.personal_inn .section-title h1 {
	float: right;
	max-width: 68%;
}
.during-area {
	position: relative;
	padding-top: 40px;
	background-color: #fff;
	margin-top: 0px;
	display: flex;
	padding-left: 0;
}
.during-inn {
	width: 100%;
}
.during-content {
	height: 100%;
	display: flex;
}
.during-content p {
	padding-top: 20px;
}
.during-image img {
	object-fit: cover;
	z-index: 9;
	position: relative;
}
.during-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0%;
	width: 100%;
}
.during-inn .content_section {
	left: 11%;
	position: relative;
}
.during-inn .section-title {
	position: relative;
	display: flex;
	margin-top: -100px;
	margin-bottom: 65px
}
.during-inn .section-title h1 {
	color: #50D0DB;
	font-family: 'league_spartanbold';
	font-size: 37px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 50px;
	max-width: 496px;
}
.during-inn .section-title::before {
	position: absolute;
	content: '';
	border-top: 1px solid #aaa;
	width: 50%;
	right: 0;
	top: -20px;
}
.during-inn .section-title::after {
	position: absolute;
	content: '';
	border-bottom: 1px solid #aaa;
	width: 37%;
	left: 0;
	bottom: -20px;
}
.emclass-inpage-area {
	position: relative;
	padding-top: 100px;
	background-color: #EFEFEF;
	margin-top: -60px;
	display: flex;
}
.emclass-inpage_inn {
	width: 100%;
}
.emclass-inpage-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.emclass-inpage-content p {
	padding-top: 20px;
}
.emclass-inpage-image img {
	width: 100%;
	object-fit: cover;
	max-width: 640px;
	float: right;
}
.emclass-inpage-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 465px;
	margin: 40px 0 40px 0;
	width: 100%;
}
.emclass-inpage_inn {
	display: block;
	width: 100%;
}
.emclass-inpage_inn .content_section {
	left: 0px;
	position: relative;
	float: right;
}
.emclass-inpage_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
	width: 60%;
	text-indent: 35px;
}
.emclass-inpage_inn .section-title::before {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin-left: 0;
	border-top: 1px solid #aaa;
}
.emclass-inpage-image {
	position: relative;
	z-index: 9;
}
.emborrow-inpage-area {
	position: relative;
	padding-top: 140px;
	background-color: #fff;
	margin-top: -130px;
	display: flex;
}
.emborrow-inpage_inn {
	width: 100%;
}
.emborrow-inpage-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.emborrow-inpage-content p {
	padding-top: 20px;
}
.emborrow-inpage-area-image img {
	object-fit: cover;
	z-index: 9;
	position: relative;
}
.emborrow-inpage-area-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 40px 0 40px 0%;
	width: 100%;
}
.emborrow-inpage_inn .content_section {
	left: 11%;
	position: relative;
}
.emborrow-inpage_inn .section-title {
	position: relative;
	display: flex;
	align-items: center;
}
.emborrow-inpage_inn .section-title::after {
	content: '';
	-webkit-box-flex: 1;
	box-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-box-ordinal-group: 2;
	box-ordinal-group: 2;
	-moz-order: 2;
	order: 2;
	-ms-flex-order: 2;
	margin-left: 3.5em;
	border-top: 1px solid #aaa;
}
.emborrow-inpage-area-content {
	height: 100%;
	display: flex;
	align-items: center;
}
.footer-area.footer {
	background: #fff;
	width: 70%;
	margin: 0 auto;
	border-top: 1px solid #D5D5D5;
	text-align: center;
	max-width: 1140px
}
.nav-footer.justify-content-center a {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 18px;
	letter-spacing: 0;
	line-height: 32px;
	text-align: center;
	margin: 0 15px;
}
.nav-footer.justify-content-center a:hover {
	text-decoration: underline;
}
.footer-copyright {
	border-top: 1px solid #D5D5D5;
}
.footer-copyright.copyright .copyright p {
	color: #959595;
	font-family: 'LatoWeb';
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 10px
}
.footer-copyright.copyright .copyright-menu ul li a {
	color: #fff;
}
.copyright-menu ul li {
	display: inline-block;
	margin-left: 10px;
}
.copyright-menu ul li a {
	font-size: 16px;
	font-family: 'LatoWeb';
	color: #63688e;
}
 @media screen and (min-width:1921px) {
.partners-area.partners_inpage {
     padding-left: 0;
}
.personal-area {
     padding-right: 0;
 }
 .during-area {
    padding-left: 0;
}
.emclass-inpage-area {
     padding-right: 0;
}
.emborrow-inpage-area {
     padding-left: 0;
}
.partners-area {
     padding-left: 0;
}
.members-area {
     padding-right: 0;
}
.emclass-area {
     padding-left: 0;
}
.emborrow-area {
    padding-right: 0;
}
 }
 @media screen and (min-width:1900px) {
.members-image img {
	min-width: 85%;
}
.partners-image img {
	min-width: 85%;
}
.emclass-image img {
	min-width: 85%;
}
.emborrow-image img {
	min-width: 85%;
}
.personal-image img {
	min-width: 85%;
}
.during-image img {
	object-fit: cover;
	z-index: 9;
	position: relative;
	min-width: 85%;
}
.emclass-inpage-image img {
	min-width: 85%;
}
.emborrow-inpage-area-image img {
	min-width: 85%;
}
.during-inn .section-title::before {
	width: 60%;
}
.during-inn .section-title::after {
	width: 29%;
}
.members_inn .section-title {
	width: 66%;
}
.emborrow_inn .section-title {
	width: 68%;
	text-indent: 0;
}
.emclass-inpage_inn .section-title {
	width: 66%;
	text-indent: 50px;
}
}
 @media screen and (min-width:1279px) and (max-width:1399px) {
.header-content-area .header-content {
	position: relative;
	z-index: 5;
	padding-top: 160px;
	max-width: 540px;
}
.header-content-area .header-content .header-title {
	color: #293045;
	font-size: 50px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: normal;
	font-family: 'league_spartanbold';
	max-width: 600px;
}
.header-area {
	background-color: #fff;
	min-height: 700px;
	padding: 0 4%;
}
.personal_inn .section-title h1 {
	font-size: 50px;
}
.members-image img {
	max-width: 540px
}
.partners-image img {
	max-width: 540px
}
.emclass-image img {
	max-width: 540px
}
.emborrow-image img {
	max-width: 540px;
}
.personal-image img {
	max-width: 540px
}
.during-image img {
	max-width: 540px
}
.emclass-inpage-image img {
	max-width: 540px;
}
.emborrow-inpage-area-image img {
	max-width: 540px
}
}
 @media screen and (min-width:1025px) and (max-width:1399px) {
.personal_inn .section-title h1 {
	float: right;
	max-width: 68%;
	font-size: 60px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 65px;
}
}
 @media screen and (min-width:992px) and (max-width:1199px) {
h1 {
	color: #293045;
	font-family: 'league_spartanbold';
	font-size: 50px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 66px;
}
.header-area {
	background-color: #fff;
	min-height: 600px;
	padding: 0 4%;
}
.header-content-area .header-content .header-title {
	color: #293045;
	font-size: 40px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 58px;
	font-family: 'league_spartanbold';
	max-width: 600px;
}
.content_section {
	max-width: 80%
}
.members_inn .content_section {
	left: -30px;
	position: relative;
	float: right;
	padding-bottom: 140px;
}
.emclass_inn .content_section {
	left: 11%;
	position: relative;
	padding-bottom: 100px;
}
.personal_inn .section-title h1 {
	float: right;
	max-width: 68%;
	font-size: 40px;
	line-height: normal;
}
.personal_inn .content_section {
	padding-left: 11%;
	float: right;
}
.during-inn .section-title {
	position: relative;
	display: flex;
	margin-top: 0;
	margin-bottom: 65px;
}
.emclass-inpage-area {
	position: relative;
	padding-top: 100px;
	background-color: #EFEFEF;
	margin-top: 0;
	display: flex;
	padding-right: 4%;
}
.emborrow-inpage-area {
	position: relative;
	padding-top: 100px;
	background-color: #fff;
	margin-top: -80px;
	display: flex;
	padding-left: 4%;
}
}

 @media screen and (min-width:992px) {
.partners-area.partners_inpage.mob_view{ display:none}
 .during-area.mob_view {
    display: none;
}
.emborrow-inpage-area.mob_view {
    display: none;
}
.partners-area.partners.mob_view {
    display: none;
}
.emclass-area.emclass.mob_view {
    display: none;
}
}

 @media screen and (max-width:991px) {
	 .partners-area.partners.desk_view {
    display: none;
}
.emclass-area.emclass.desk_view {
    display: none;
}
 .during-area.desk_view {
    display: none;
}
.emborrow-inpage-area.desk_view {
    display: none;
}
.header-area {
	background-color: #fff;
	min-height: auto;
	padding: 0 4%;
}
.header-content-area .header-banner-bg {
	width: 100%;
	height: 100%;
	position: relative;
}
.header-content-area .header-content {
	position: relative;
	z-index: 5;
	padding-top: 40px;
	max-width: 100%;
}
.header-content-area .header-content .header-title {
	font-family: 'league_spartanbold';
	font-size: 35px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 43px;
	text-align: center;
}
.header-content-area .header-content p {
	font-family: 'LatoWeb';
	font-size: 22px;
	text-align: center;
	margin-bottom: 30px;
}
.partners-image {
	max-width: 96%;
}
.partners-image img {
	margin: 0 auto;
	display: block;
}
h1 {
	color: #293045;
	font-family: 'league_spartanbold';
	font-size: 35px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 86px;
}
.partners-area {
    position: relative;
    padding-top: 36px;
    overflow-x: hidden;
}
.partners-content {
    height: 100%;
    padding-bottom: 40px;
}
.partners_inn .section-title::after {
	margin-left: 1.5em;
	border-top: 1px solid #aaa;
}
.partners_inn .content_section {
	left: 11%;
	position: relative;
	max-width: 83%;
}
.partners-content p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 30px;
}
.members-area {
    position: relative;
    padding-top: 40px;
    background-color: #fff;
    margin-top: -60px;
    display: flex;
    padding-right: 0;
    padding-left: 4%;
	overflow-x: hidden;
}
.members-image {
	max-width: 96%;
}
.members-image img {
	margin: 0 auto;
	display: block;
	float: none;
}
.members_inn .section-title::before {
    margin-left: 1.5em;
    border-top: 1px solid #aaa;
    order: 2;
    margin-right: 0;
}

.emclass_inn .section-title::after {
     margin-left: 1.5em;
 
}
.header-area.header-area_inn_pages .navbar {
    padding: 20px 0;
}
.members_inn .section-title {
	text-indent: 0;
	width: 100%;
}
.members_inn .content_section {
	left: 11%;
	position: relative;
	float: left;
	max-width: 85%;
	padding-bottom: 40px;
}
.members_inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 30px;
}
.emclass-area {
    position: relative;
    padding-top: 80px;
    background-color: #EFEFEF;
    margin-top: -230px;
    display: flex;
    padding-left: 4%;
	overflow-x: hidden;
}
.emclass-image {
	max-width: 96%;
}
.emclass-image img {
	margin: 0 auto;
	display: block;
}
.emclass_inn .section-title::before {
	margin-left: 1.5em;
	border-top: 1px solid #aaa;
	order: 2;
}
.emclass_inn .section-title {
	text-indent: 0;
	width: 100%;
}
.emclass_inn .content_section {
	left: 11%;
	position: relative;
	float: left;
	max-width: 85%;
	padding-bottom:40px;
}
.emclass_inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 30px;
}
.emborrow-area {
    position: relative;
    padding-top: 40px;
    background-color: #fff;
    margin-top: -60px;
    display: flex;
    padding-right: 0;
    padding-left: 4%;
	overflow-x: hidden;
}
.emborrow-area.emborrow {
    padding-bottom: 70px;
}
.emborrow-image {
	max-width: 96%;
}
.emborrow-image img {
	margin: 0 auto;
	display: block;
	float: none;
}
.emborrow_inn .section-title::before {
    margin-left: 1.5em;
    border-top: 1px solid #aaa;
    order: 2;
    margin-right: 0;
}
.emborrow_inn .section-title {
	text-indent: 0;
	width: 100%;
}
.emborrow_inn .content_section {
	left: 11%;
	position: relative;
	float: left;
	max-width: 85%;
	padding-bottom: 40px;
}
.emborrow_inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 30px;
}
.personal-area {
	position: relative;
	padding-top: 0;
	background-color: #fff;
	margin-top: -60px;
	display: flex;
	padding-right: 0;
	padding-left: 4%;
	padding-bottom: 0;
	overflow-x: hidden;
}
.personal-image {
	max-width: 96%;
}
.personal-image img {
	margin: 0 auto;
	display: block;
	float: none;
}
.personal_inn .section-title::before {
	margin-left: 1.5em;
	border-top: 1px solid #aaa;
	order: 2;
	margin-right: 0;
}
.personal_inn .section-title {
	text-indent: 0;
	width: 100%;
}
.personal-area {
    position: relative;
    padding-top: 0;
    background-color: #fff;
    margin-top: 0;
    display: flex;
    padding-right: 0;
    padding-left: 4%;
    padding-bottom: 0;
}
.personal_inn .content_section p {
    color: #293045;
    font-family: 'LatoWeb';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    max-width: 458px;
    margin: 0 0 0 10%;
    width: 87%;
    padding-bottom: 0px;
    float: none;
}
.personal_inn .section-title h1 {
	float: right;
	max-width: 68%;
	line-height: normal;
}
.during-area {
	position: relative;
	padding-top: 50px;
	margin-top: 0;
	display: flex;
	padding-left: 4%;
	overflow-x: hidden;
}
.during-image {
	max-width: 96%;
}
.during-image img {
	margin: 0 auto;
	display: block;
}
.during-inn .section-title {
	text-indent: 0;
	width: 100%;
}
.during-inn .content_section {
	left: 11%;
	position: relative;
	float: left;
	max-width: 85%;
}
.during-inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 30px;
}
.during-inn .section-title {
	position: relative;
	display: flex;
	margin-top: 40px;
	margin-bottom: 65px;
}
.during-inn .section-title h1 {
	color: #50D0DB;
	font-family: 'league_spartanbold';
	font-size: 35px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 45px;
	max-width: 496px;
}
.emclass-inpage-area {
    position: relative;
    padding-top: 30px;
    margin-top: -50px;
    display: flex;
    padding-right: 0;
    padding-left: 4%;
	overflow-x: hidden;
}
.emclass-inpage-image {
	max-width: 96%;
}
.emclass-inpage-image img {
	margin: 0 auto;
	display: block;
	float: none;
}
.emclass-inpage_inn .section-title::before {
	margin-left: 1.5em;
	border-top: 1px solid #aaa;
	order: 2;
}
.emclass-inpage_inn .section-title {
	text-indent: 0;
	width: 100%;
}
.emclass-inpage_inn .content_section {
	left: 11%;
	position: relative;
	float: left;
	max-width: 85%;
	padding-bottom: 40px;
}
.emclass-inpage_inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 0px;
}
.emborrow-inpage-area {
	position: relative;
	padding-top: 140px;
	background-color: #fff;
	margin-top: -130px;
	display: flex;
	padding-left: 4%;
	overflow-x: hidden;
}
.emborrow-inpage-area-image {
	max-width: 96%;
}
.emborrow-inpage-area-image img {
	margin: 0 auto;
	display: block;
}
.emborrow-inpage_inn .section-title::before {
	margin-left: 1.5em;
	border-top: 1px solid #aaa;
	order: 2;
}
.emborrow-inpage_inn .section-title {
    text-indent: 0;
    width: 100%;
    margin: -40px 0 0 0;
}
.emborrow-inpage_inn .content_section {
    left: 11%;
    position: relative;
    float: left;
    max-width: 85%;
    padding-bottom: 40px;
}
.emborrow-inpage_inn .content_section p {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	max-width: 458px;
	margin: 0;
	width: 100%;
	padding-bottom: 0;
}
.partners-area.partners_inpage {
	padding-top: 100px;
}
}

/* custom */
.coming-soon-contain {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}
.coming-soon-contain>div {
	display: flex;
    flex-direction: column;
    align-items: center;
}
.emclass-logo-contain {
	width: 184px;
    height: 38px;
}
.coming-soon-contain .separate-line {
	box-sizing: border-box;
	height: 1px;
	width: 338px;
	border: 1px solid #D6D6D6;
	margin-top: 32px;
	margin-bottom: 8px;
}
.coming-soon-contain img {
	width: 100%;
}
.coming-soon-title {
	height: 85px;
	width: 940px;
	color: #1D2231;
	font-family: "league_spartanbold";
	font-size: 62px;
	letter-spacing: 0;
	line-height: 85px;
	text-align: center;
}
.row {
	margin-left: 0;
	margin-right: 0;
}
.contact-modal-contain .header {
	padding-top: 30px;
	padding-right: 33px;
	display: flex;
	flex-direction: row-reverse;
}
.contact-modal-contain .header .close-button {
	color: #50D0DB;
	font-family: 'LatoWeb';
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	text-align: right;
}
.contact-modal-contain .header .close-button:hover {
	cursor: pointer;
}
.contact-modal-contain .title {
	color: #293045;
	font-family: "league_spartanbold";
	font-size: 65px;
	letter-spacing: 0;
	line-height: 86px;
	padding-left: 100px;
}
.contact-info {
	border-top: solid 1px #AAAAAA;
	padding-top: 40px;
}
.contact-modal-content {
	height: 100vh;
	padding: 0 !important;
	margin: 'unset' !important;
}
.modal-content {
	width: 1140px;
	margin: auto;
	border: unset !important;
}
.contact-modal-content .alert-contain {
	color: red;
	font-size: 20px;
	margin-bottom: 20px;
}
@media (min-width: 576px) {
	.modal-dialog {
		max-width: unset;
		margin: auto;
	}
}
@media (min-width: 576px) {
	.modal-dialog-centered {
		min-height: unset !important;
	}
}

 
.contact-info-item {
	display: flex;
	padding-left: 90px;
    padding-bottom: 40px;
}
.contact-info-item .description {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
}
.contact-info-item img {
	margin-right: 20px;
}
.contact-modal-contain .col-md-7 {
	padding: 50px;
}
.contact-modal-contain .input-element {
	box-sizing: border-box;
	height: 57px;
	border: 1px solid #C0C0C0;
	color: #9FA4B3;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	padding-left: 16px;
	width: 100%;
}
.input-item {
	margin-bottom: 25px;
}
.input-item .label {
	color: #293045;
	font-family: 'LatoWeb';
	font-size: 20px;
	letter-spacing: 0;
	line-height: 24px;
	margin-bottom: 15px;
}
.input-name-contain {
	display: flex;
	justify-content: space-between;
}
.input-name-contain .input-element:first-child{
	margin-right: 20px;
}
.input-name-contain .input-element {
	width: 50%;
}
.contact-modal-contain .input-element.text-area {
	height: 150px;
	padding: 16px;
}
.contact-modal-contain .input-element.text-area::placeholder {
	color: #9FA4B3;
	font-family: 'LatoWeb';
	font-size: 20px;
}
.submit-button {
	height: 56px;
  	width: 186px;
	color: #FFFFFF;
	background-color: #50D0DB;
	font-family: 'LatoWeb';
	font-size: 20px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.submit-button:hover {
	cursor: pointer;
}
.submit-button:active {
	background-color: #066972;
}
@media(max-width:768px) {
	.contact-modal-content {
		margin: '10px auto'
	} 
	.contact-modal-contain .title {
		padding-left: 0;
	}
	.contact-info-item {
		padding-left: 0;
	}
	.contact-modal-contain .col-md-7 {
		padding: 0 10px;
	}
	.modal-content {
		padding-bottom: 35px;
	}
}